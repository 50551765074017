
import React from 'react';
import styles from '../../assets/css/Header.module.css';// Import the CSS file

const Header: React.FC = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logo}>Logo</div>
      <div className={styles.siteName}>COMFYAI</div>
      <div className={styles.menuIcon}>☰</div>
    </header>
  );
};

export default Header;